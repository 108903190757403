import React, {useEffect, useState} from 'react'
import {getTextForPractice} from "../../api/keyboardText";
import {IKeyboardText} from "../../Common/models";
import {Button} from "@sberbusiness/triplex/components/Button/Button";
import {EButtonSize, EButtonTheme} from "@sberbusiness/triplex/components/Button/enums";
import {KeyboardSimulator} from "../../Modules/KeyboardSimulator/KeyboardSimulator";
import {EKeyboardSimulatorTypes} from "../../Modules/KeyboardSimulator/enums";


export const PracticePage: React.FC = () => {
    const [textData, setTextData] = useState<IKeyboardText | null>(null);

    useEffect(() => {
        getTextForPractice()
            .then((res) => {
                if (res.status === 200) {
                    setTextData(prev => ({
                        ...prev,
                        text: res.data.textValue,
                        textLength: Number(res.data.textValue)
                    }));
                }
            })

    }, []);

    return (
        <>
            <KeyboardSimulator keyboardText={'фыафыа'} type={EKeyboardSimulatorTypes.PRACTICE}/>
        </>
    )
}

PracticePage.displayName = 'PracticePage'
