import React from 'react'
import { Skeleton } from '@sberbusiness/triplex/components/Skeleton/Skeleton';
import { Text } from '@sberbusiness/triplex/components/Typography/Text';
import { ELineType, ETextSize } from '@sberbusiness/triplex/components/Typography/enums';

import styles from './TableCell.module.css';

/**
 * Ячейка таблицы
 *
 * @prop value Значение
 * @prop isLoading Флаг загрузки данных
 */
interface IProps {
    value: string;
    isLoading: boolean;
}

export const TableCell: React.FC<IProps> = ({value, isLoading}: IProps) => {

    if (isLoading) {
        return <div className={styles.skeleton}><Skeleton/></div>
    }

    return (
        <div className={styles.cell}>
            <Text size={ETextSize.B1} line={ELineType.EXTRA}>{value}</Text>
        </div>
    )
}

TableCell.displayName = 'TableCell'
