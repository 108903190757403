import {useEffect} from "react"

/**
 * Хук отключает прокручивание страницы вниз при нажатии на клавишу пробела.
 */
export const useDisableSpacebarPress = () => {
    useEffect(() => {
        const handleDisableSpacebarPress = (event: KeyboardEvent) => {
            if (event.code === 'Space') {
                event.preventDefault()
            }
        }

        document.addEventListener('keydown', handleDisableSpacebarPress)

        return () => {
            document.removeEventListener('keydown', handleDisableSpacebarPress)
        }
    }, [])
}
