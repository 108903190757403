import axios from "axios";
import {getAuthToken} from "../Common/Utils";

export const BASE_URL = 'https://sberciboard.ru'

export const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: `${BASE_URL}/api`
})

axiosInstance.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
        config.headers['x-access-token'] = token;
    }

    return config;
})

export default axiosInstance
