import React, {useState, useEffect} from 'react';
import {IResetPasswordFormData} from "../../Components/models";
import {useNavigate, useParams} from "react-router-dom";
import classNames from "classnames/bind";
import {InputField} from "../../Common/Components/InputField/InputField";
import {Gap} from '@sberbusiness/triplex/components/Gap/Gap';
import {verifyResetToken, resetPassword} from '../../api/auth';
import {EResponseStatus} from '../../api/types';
import {validateEqualPasswords, validatePasswordLength} from '../../Components/utils';
import {ROUTE_PATH} from '../../Routes/Routes';
import {ELinkSize, ELinkType, Link} from '@sberbusiness/triplex/components/Link/Link';
import {Button} from "@sberbusiness/triplex/components/Button/Button";
import {EButtonSize, EButtonTheme} from '@sberbusiness/triplex/components/Button/enums';

import styles from './ResetPasswordPage.module.css';

const cx = classNames.bind(styles)

const initialData: IResetPasswordFormData = {
    password: '',
    passwordRepeat: ''
}

export const ResetPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const { token } = useParams();

    const [invalidFields, setInvalidFields] = useState(Object.fromEntries(
        Object.entries(initialData).map(([key]) => [key, true])
    ));

    const [formState, setFormState] = useState(initialData);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        setInvalidFields(prevState => ({
            ...prevState,
            password: !(validateEqualPasswords(formState.password, formState.passwordRepeat) || validatePasswordLength(formState.password)),
            passwordRepeat: !(validateEqualPasswords(formState.password, formState.passwordRepeat) || validatePasswordLength(formState.password)),
        }))
    }, [formState.password, formState.passwordRepeat])

    const [formValidation, setFormValidation] = useState({
        isValid: true,
        errorMessage: ''
    });

    const [verified, setVerified] = useState(false);

    const handleChange = () => (fieldName: string) => (fieldValue: string) => {
        setFormState({
            ...formState,
            [fieldName]: fieldValue,
        });
    };

    useEffect(() => {
        verifyResetToken(String(token))
            .then(({ data }) => {
                const { verified } = data;
                
                setVerified(verified);
            })
            .catch((error) => {
                setVerified(false);
            })
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const hasErrors = Object.values(invalidFields).includes(false);

        if (hasErrors) {
            return;
        }

        resetPassword({ token: String(token), newPassword: formState.password }).then(() => {
            setShowSuccessMessage(true);
        }).catch((error) => {
            const status = error.response.status;
            if (status === EResponseStatus.RES_STATUS_404) {
                setFormValidation(() => ({
                    isValid: false,
                    errorMessage: 'Участник не найден'
                }));
            } else {
                setFormValidation(() => ({
                    isValid: false,
                    errorMessage: 'Ошибка при изменении пароля'
                }));
            }
        });
    };

    const handleGoSignIn = () => navigate(ROUTE_PATH.SIGN_IN);

    const errorMessage = () => {
        return (fieldName: string, fieldValue: string): string => {
            switch (fieldName) {
                case 'password':
                case 'passwordRepeat':
                    if (validatePasswordLength(fieldValue)) {
                        return 'Минимум 8 символов'
                    }
                    return 'Пароли не совпадают'
            }
            return '';
        }
    }

    const errorMessageClassNames = cx({
        errorMessage: true,
        isValidLoginForm: !formValidation.isValid
    })

    return (
        <div className={styles.resetPasswordPage}>
            {verified ? showSuccessMessage ? (
                <div>Пароль был успешно изменен</div>
            ) : (
                <div className={styles.formWrapper}>
                    <form onSubmit={handleSubmit}>
                        <InputField
                            fieldName={'password'}
                            value={formState.password}
                            type={'password'}
                            label={'Новый пароль'}
                            placeholder={'Новый пароль'}
                            onChange={handleChange()}
                            isValid={invalidFields['password']}
                            errorMessage={errorMessage()}
                            autoComplete='new-password'
                        />
                        <Gap size={16} />
                        <InputField
                            fieldName={'passwordRepeat'}
                            value={formState.passwordRepeat}
                            type={'password'}
                            label={'Новый пароль ещё раз'}
                            placeholder={'Новый пароль ещё раз'}
                            onChange={handleChange()}
                            isValid={invalidFields['passwordRepeat']}
                            errorMessage={errorMessage()}
                        />
                        <span className={errorMessageClassNames}>{formValidation.errorMessage}</span>
                        <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.SM}>
                            Изменить пароль
                        </Button>
                    </form>
                </div>
            ) : (
                <div>Невалидный или истекший токен</div>
            )}
            <Gap size={32}/>
            <Link linkType={ELinkType.TEXT} size={ELinkSize.LG} onClick={handleGoSignIn}>
                На страницу входа
            </Link>
        </div>

    )
}

ResetPasswordPage.displayName = 'ResetPasswordPage';
