import React from 'react'
import {INPUT_STRING_VALUE_MAXIMUM_LENGTH} from "../../../Components/consts";
import {Input} from "@sberbusiness/triplex/components/Input/Input";
import {FormGroup, FormGroupLine} from "@sberbusiness/triplex/components/FormGroup";
import {FormFieldDescription} from "@sberbusiness/triplex/components/FormField";
import {ETextSize} from "@sberbusiness/triplex/components/Typography/enums";
import {Text} from "@sberbusiness/triplex/components/Typography/Text";

import styles from './InputField.module.css';

/**
 * Компонент Поле формы.
 *
 * @prop fieldName Название поля.
 * @prop placeholder Плейсхолдер.
 * @prop label Подпись поля.
 * @prop value Значение.
 * @prop onChange Обработчик изменений в форме.
 * @prop [errorMessage] Сообщение об ошибке валидации поля.
 * @prop [isValid] Признак валидности поля.
 * @prop [autoComplete] Автокомплит.
 */
interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    fieldName: string;
    placeholder: string;
    label: string;
    value: string;
    onChange: (fieldName: string) => (value: string) => void;
    errorMessage?: (type: string, value: string) => string;
    isValid?: boolean;
    autoComplete?: string;
}

export const InputField: React.FC<IProps> = ({
     fieldName,
     placeholder,
     type,
     label,
     value,
     onChange,
     isValid,
     errorMessage,
     autoComplete,
 }: IProps) => {
    const handeChange = () => {
        return (event: any) => {
            const onChangeField = onChange(fieldName);
            onChangeField(event.target.value)
        }
    }

    const renderErrorDescription = () => {
        if (!errorMessage || isValid) {
            return null
        }

        return (
            <FormGroupLine>
                <FormFieldDescription error>{errorMessage(fieldName, value)}</FormFieldDescription>
            </FormGroupLine>
        )
    }

    return (
        <div className={styles.Row}>
            <div className={styles.Label}>
                <Text size={ETextSize.B1}>{label}</Text>
            </div>
            <div className={styles.Input}>
                <FormGroup>
                    <FormGroupLine flex>
                        <Input
                            type={type}
                            placeholder={placeholder}
                            error={!isValid}
                            onChange={handeChange()}
                            value={value}
                            maxLength={INPUT_STRING_VALUE_MAXIMUM_LENGTH}
                            autoComplete={autoComplete}
                        />
                    </FormGroupLine>
                    {renderErrorDescription()}
                </FormGroup>
            </div>
        </div>
    );
};
