import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {RouterProvider} from "react-router-dom";
import {router} from "../../Routes/Routes";
import React, {useEffect, useState} from "react";
import {getSettings} from "../../api/settings";
import {ISettings} from "../../api/settings/types";
import { AppSettingsContext } from "../Contexts/appSettings";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Не инвалидировать кеш по прошествию времени (только вручную)
            staleTime: Infinity,
            cacheTime: Infinity,
            // Не повторять запрос при фокусе окна
            refetchOnWindowFocus: false,
            retry: false,
            useErrorBoundary: true,
            networkMode: 'always',
        },
    },
})

const App = () => {
    const [appSettings, setAppSettings] = useState<ISettings | null>(null)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFailed, setIsFailed] = useState<boolean>(false)

    useEffect(() => {
        setIsLoading(true)
        getSettings()
            .then(res => {
                setIsLoaded(true)
                setAppSettings(res.settings);
            })
            .catch((err) => console.log('___---_-_!!!_-_---___', err))
            .finally(() => setIsLoading(false))
    }, []);

    if (isLoading) {
        return <div>Загрузка...</div>
    }

    if (isFailed) {
        return <div>Ошибка при загрузке</div>
    }

    return (
        <AppSettingsContext.Provider value={appSettings}>
            <QueryClientProvider client={queryClient}>
                <div className='app'>
                    <RouterProvider router={router} />
                </div>
            </QueryClientProvider>
        </AppSettingsContext.Provider>
    )
};

export default App
