import React, {useEffect, useState} from 'react'
import {getTextForPractice} from "../../api/keyboardText";
import {IKeyboardText} from "../../Common/models";
import {Button} from "@sberbusiness/triplex/components/Button/Button";
import {EButtonSize, EButtonTheme} from "@sberbusiness/triplex/components/Button/enums";

export const TrialPage: React.FC = () => {
    const [textData, setTextData] = useState<IKeyboardText | null>(null);

    useEffect(() => {
        getTextForPractice()
            .then((res) => {
                if (res.status === 200) {
                    setTextData(prev => ({
                        ...prev,
                        text: res.data.textValue,
                        textLength: Number(res.data.textValue)
                    }));
                }
            })

    }, []);

    return (
        <>
            <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.MD}>Начать заново</Button>
            <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.MD}>Закончить</Button>
        </>
    )
}

TrialPage.displayName = 'TrialPage'
