import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ROUTE_PATH} from "../../Routes/Routes";

import styles from "./RegistrationSuccessPage.module.css";

export const RegistrationSuccessPage: React.FC = () => {
    const navigate = useNavigate();

    const [timeLeft, setTimeLeft] = useState(5)

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(timeLeft => timeLeft >= 1 ? timeLeft - 1 : 0)
        }, 1000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        if (timeLeft === 0) {
            navigate(ROUTE_PATH.SIGN_IN)
        }
    }, [timeLeft])

    const renderTimer = `Вас перенаправит на страницу входа через ${timeLeft} секунд...`
    return (

        <div className={styles.successRegistrationWrapper}>
            <div className={styles.successRegistrationMessage}>
                <p>Вы успешно зарегистрировались <br/> в клавиатурном чемпионате SberCIBoard</p>
            </div>
            <span>{renderTimer}</span>
        </div>
    )
}


RegistrationSuccessPage.displayName = 'RegistrationSuccessPage';
