import React, {useState} from "react";
import {MasterTable} from "@sberbusiness/triplex/components/Tables/MasterTable";
import {LeaderboardRecord, SortDirection, SortParameter} from "../../api/leaderboard/types";
import {useLeaderboardTableData} from "../../api/leaderboard";
import {ELineType, ETextSize} from "@sberbusiness/triplex/components/Typography/enums";
import {Text} from "@sberbusiness/triplex/components/Typography/Text";
import {EHorizontalAlign, EOrderDirection} from "@sberbusiness/triplex/components/Tables/TableBasic/enums";
import {ISortOrder, ITableBasicColumn} from "@sberbusiness/triplex/components/Tables/TableBasic/types";
import {Skeleton} from '@sberbusiness/triplex/components/Skeleton/Skeleton';

import styles from "./LeaderboardPage.module.css";

export const INIT_PAGE = 1;
export const ROW_SIZE = 10;

const OrderDirectionMap = {
    [EOrderDirection.ASC]: SortDirection.ASCENDING,
    [EOrderDirection.DESC]: SortDirection.DESCENDING,
    [EOrderDirection.NONE]: SortDirection.NONE,
}

export const LeaderboardTable = () => {
    const [order, setOrder] = useState({field: 'speedMax', direction: EOrderDirection.ASC});

    const [page, setPage] = useState(INIT_PAGE);

    const sortBy = order.field as SortParameter
    const sortDirection = OrderDirectionMap[order.direction];

    const {
        data,
        isLoading,
        isFetching,
    } = useLeaderboardTableData({sortBy, sortDirection, page})

    const [rowNumber, setRowNumber] = useState(ROW_SIZE);

    const rowNumberOptions = [10];

    const isLoadingData = isLoading || isFetching;

    const renderCell = (value: string) => {
        if (isLoadingData) {
            return <div className={styles.Skeleton}><Skeleton/></div>
        }

        return (
            <div className={styles.Cell}>
                <Text size={ETextSize.B1} line={ELineType.EXTRA}>{value}</Text>
            </div>
        )
    }

    const columns: ITableBasicColumn[] = [
        {
            fieldKey: 'number',
            label: 'Место',
            width: 80,
            renderCell
        },
        {
            fieldKey: 'login',
            label: 'Никнейм',
            width: 160,
            renderCell
        },
        {
            fieldKey: 'surname',
            label: 'ФИО',
            width: 300,
            renderCell
        },
        {
            fieldKey: 'speedMax',
            label: 'Макс. скорость',
            width: 150,
            renderCell
        },
        {
            fieldKey: 'accuracyMax',
            label: 'Макс. точность',
            width: 150,
            renderCell
        },
    ];

    const formattedColumns = columns.map(item => {
        if (item.fieldKey === 'number') {
            return item
        }

        const newItem = {
            ...item,
            orderColumnKey: order.field,
            orderDirection: EOrderDirection.NONE,
            horizontalAlign: EHorizontalAlign.LEFT,
        }

        if (newItem.fieldKey === order.field) {
            newItem.orderDirection = order.direction
        } else {
            newItem.orderDirection = EOrderDirection.NONE
        }

        return newItem
    })

    const formattedData = (data: LeaderboardRecord[]) => {
        return data?.map((item, idx) => ({
            rowKey: `table-basic-row-${item?.user?.name}-${idx}`,
            rowData: {
                number: String(idx + 1),
                login: item.user.login,
                surname: `${item.user.name} ${item.user.surname}`,
                speedMax: String(item.speedMax),
                accuracyMax: String(item.accuracyMax),
            }
        }))
    }

    const handleSelectRowNumber = (number: number) => {
        !isLoadingData && setRowNumber(number);
    }

    const handleClickNextPage = () => {
        !isLoadingData && setPage(page + 1);
    }

    const handleClickPrevPage = () => {
        !isLoadingData && setPage(page - 1);
    }

    const handleOrderBy = (order: ISortOrder) => {
        if (!isLoadingData) {
            setPage(INIT_PAGE)

            setOrder({
                field: order.fieldKey,
                direction: order.direction
            });
        }
    }

    return (
        <div className={styles.Container}>
            <div className={styles.Table}>
                <MasterTable>
                <MasterTable.TableBasic
                    columns={formattedColumns}
                    data={formattedData(data?.records || [])}
                    renderNoData={() => <Text size={ETextSize.B1}>Нет данных</Text>}
                    onOrderBy={handleOrderBy}
                />
                {data?.totalPages && (
                    <MasterTable.Pagination
                        paginationLabel="Показать на странице"
                        rowNumber={rowNumber}
                        rowNumberOptions={rowNumberOptions}
                        currentPageNumber={page}
                        onSelectRowNumber={handleSelectRowNumber}
                        onClickPrevPage={handleClickPrevPage}
                        onClickNextPage={handleClickNextPage}
                        hasPrevPage={data?.hasPrevPage ?? false}
                        hasNextPage={data?.hasNextPage ?? false}
                    />
                )}
                </MasterTable>
            </div>
        </div>
    )
};

LeaderboardTable.displayName = "LeaderboardTable";
