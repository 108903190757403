import axiosInstance from "..";
import {IAuthResponse, ICreateResetLinkRequest, IResetPasswordResponse, IUserRegistrationRequest, IVerifyResetTokenResponse} from "./types";
import {AxiosResponse} from "axios";

const signInUser = (login: string, password: string): Promise<AxiosResponse<IAuthResponse>> => {
    return axiosInstance.post<IAuthResponse>('/auth/login', {login, password})
}

const signUpUser = (body: IUserRegistrationRequest): Promise<AxiosResponse<IAuthResponse>> => {
    return axiosInstance.post('/auth/register', body)
}

const refreshAccessToken = (): Promise<AxiosResponse<IAuthResponse>> => {
    return axiosInstance.get('/auth/refresh-token')
}

const signOutUser = (): Promise<AxiosResponse> => {
    return axiosInstance.get('/auth/logout')
}

const createResetLink = (body: ICreateResetLinkRequest ): Promise<AxiosResponse> => {
    return axiosInstance.post('/auth/forgot-password', body);
}

const verifyResetToken = (token: string): Promise<AxiosResponse<IVerifyResetTokenResponse>> => {
    return axiosInstance.get(`/auth/verify-reset-token/${token}`);
}

const resetPassword = (body: IResetPasswordResponse): Promise<AxiosResponse> => {
    return axiosInstance.post('/auth/reset-password', body);
}

export {signInUser, signUpUser, refreshAccessToken, signOutUser, createResetLink, verifyResetToken, resetPassword}
