import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from '../../Common/Components/Link/Link';
import { ROUTE_PATH } from "../../Routes/Routes";
import { Modal } from '../../Common/Components/Modal/Modal';
import { HeaderPage } from '../../Common/Components/HeaderPage/HeaderPage';
import { useSignOutUser } from '../../Common/Hooks/useSignOutUser';
import { KeyboardSimulator } from '../../Modules/KeyboardSimulator/KeyboardSimulator';
import { useCheckJWT} from '../../Hooks/useCheckJWT';
import { Button} from '@sberbusiness/triplex/components/Button/Button';
import { EButtonSize, EButtonTheme} from '@sberbusiness/triplex/components/Button/enums';
import jwt_decode from 'jwt-decode';
import { getAuthToken } from '../../Common/Utils';
import { fetchUserResults } from '../../api/championship';
import { IChampionship } from '../../api/championship/types';
import { AppSettingsContext } from '../../Core/Contexts/appSettings';
import { EKeyboardSimulatorTypes } from '../../Modules/KeyboardSimulator/enums';
import { ResultsTable } from './Components/ResultsTable/ResultsTable';
import {Tooltip} from "@sberbusiness/triplex/components/Tooltip/Tooltip";
import {ETooltipSize} from "@sberbusiness/triplex/components/Tooltip/enums";

import styles from './MainPage.module.css';

const checkEnableChampionshipByAttempts = (maxAttemptsCounter: number, attemptsCounter: number) => {

    return maxAttemptsCounter > attemptsCounter
}

export const MainPage: React.FC = () => {
    const navigate = useNavigate();
    const hasJWT = useCheckJWT()
    const appSettings = useContext(AppSettingsContext)
    const {handleSignOut} = useSignOutUser(navigate)

    const [userResults, setUserResults] = useState<IChampionship[] | null>(null)

    const [isLoadingResults, setIsLoadingResults] = useState(false)

    const [isOpenTooltip, setIsOpenTooltip] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [isEnableByAttemptsCounter, setIsEnableByAttemptsCounter] = useState(false)

    const token = getAuthToken();
    const userData: any = token && jwt_decode(token);

    const handleGoSignIn = () => navigate(ROUTE_PATH.SIGN_IN);

    const handleGoSignUp = () => navigate(ROUTE_PATH.SIGN_UP);

    const handleGoLeaderboard = () => navigate(ROUTE_PATH.LEADERBOARD);

    useEffect(() => {
        const fetchData = () => {
            fetchUserResults({login: userData?.sub}).then(res => {
                setUserResults(res.results)

                const roundAttempts = res.results.find((result) => {
                    return result.stageNumber === appSettings?.currentChampionshipRound
                })?.attemptsCounter

                if (appSettings?.maxAttemptsCounterForRound && roundAttempts) {
                    const enableByAttempts = checkEnableChampionshipByAttempts(appSettings?.maxAttemptsCounterForRound, roundAttempts)

                    setIsEnableByAttemptsCounter(enableByAttempts)
                }
            }).finally(() => setIsLoadingResults(false))
        }
        setIsLoadingResults(true)
        fetchData()

    }, []);

    const getHeaderControls = () => {
        const actions: JSX.Element[] = []

        if (hasJWT) {
            actions.push(<Link key="SingOutLink" title="Выход" onClick={handleSignOut}/>)
        } else {
            actions.push(...[
                <Link key="siптInLink" title="Вход" onClick={handleGoSignIn}/>,
                <Link key="signUpLink" title="Регистрация" onClick={handleGoSignUp}/>
            ])
        }

        return actions
    }

    const handleMouseOverButton = () => {
        if (appSettings?.isEnableChampionship && isEnableByAttemptsCounter) {
            return
        }
        setIsOpenTooltip(true)
    }

    const tooltipTitle = () => {
        if (!appSettings?.isEnableChampionship) {
            return 'В данный момент турнир недоступен.'
        }
        if (!isEnableByAttemptsCounter) {
            return `У Вас закончились попытки для прохождения ${appSettings.currentChampionshipRound}–го этапа.`
        }
        return
    }

    const renderMainBody = () => {
        if (hasJWT) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.main}>
                        <Button size={EButtonSize.MD} theme={EButtonTheme.GENERAL} onClick={() => setIsModalOpen(true)}>
                            Старт
                        </Button>
                        <Tooltip size={ETooltipSize.LG} isOpen={isOpenTooltip} toggle={setIsOpenTooltip}>
                            <Tooltip.Body>{tooltipTitle()}</Tooltip.Body>
                            <Tooltip.Target>
                                <Button
                                    onMouseOut={() => setIsOpenTooltip(false)}
                                    onMouseOver={handleMouseOverButton}
                                    disabled={!appSettings?.isEnableChampionship || !isEnableByAttemptsCounter}
                                    size={EButtonSize.MD}
                                    theme={EButtonTheme.SECONDARY}
                                    onClick={() => navigate(ROUTE_PATH.CHAMPIONSHIP)}
                                >
                                    Участвовать в турнире
                                </Button>
                            </Tooltip.Target>
                        </Tooltip>
                        <Link title={'Таблица лидеров'} onClick={handleGoLeaderboard}/>
                    </div>
                </div>
            )
        } else {
            return <KeyboardSimulator keyboardText={'фыафыа'} type={EKeyboardSimulatorTypes.PRACTICE}/>
        }
    }

    return (
        <>
            <HeaderPage subtitle="Главная страница" controls={getHeaderControls()}/>
            {hasJWT && (
                <div className={styles.tableWrapper}>
                    <ResultsTable isLoadingData={isLoadingResults} resultsData={userResults || []} />
                </div>
            )}
            {renderMainBody()}
            <Modal
                isOpen={isModalOpen}
                contentText={'Перед началом соревнования убедитесь, что у вас включена русская раскладка клавиатуры.'}
                btnActionText='Вперед!'
                btnAction={() => {
                    navigate(ROUTE_PATH.TRAINER)
                    setIsModalOpen(false)
                }}
            />
        </>
    )
}


MainPage.displayName = 'MainPage';
