import React, {useState} from 'react';
import {ICreateResetLinkFormData} from "../../Components/models";
import {useNavigate} from "react-router-dom";
import classNames from "classnames/bind";
import {InputField} from "../../Common/Components/InputField/InputField";
import {Gap} from '@sberbusiness/triplex/components/Gap/Gap';
import {createResetLink} from '../../api/auth';
import {EResponseStatus} from '../../api/types';
import {ROUTE_PATH} from '../../Routes/Routes';
import {ELinkSize, ELinkType, Link} from '@sberbusiness/triplex/components/Link/Link';
import {EButtonSize, EButtonTheme} from '@sberbusiness/triplex/components/Button/enums';
import {Button} from '@sberbusiness/triplex/components/Button/Button';
import {Title} from '@sberbusiness/triplex/components/Typography/Title';
import {EFontWeight, ETitleSize} from '@sberbusiness/triplex/components/Typography/enums';

import styles from './ForgotPasswordPage.module.css';

const cx = classNames.bind(styles)

const initialData: ICreateResetLinkFormData = {
    email: '',
}

export const ForgotPasswordPage: React.FC = () => {
    const navigate = useNavigate();

    const [formValidation, setFormValidation] = useState({
        isValid: true,
        errorMessage: ''
    })

    const [hasBeenSubmitted, setHasBeenSubmited] = useState(false);
    const [formState, setFormState] = useState(initialData);

    const handleChange = () => (fieldName: string) => (fieldValue: string) => {
        setFormState({
            ...formState,
            [fieldName]: fieldValue,
        });
    };

    const handleGoSignIn = () => navigate(ROUTE_PATH.SIGN_IN);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        createResetLink(formState).then(async res => {
            setHasBeenSubmited(true);
        }).catch(error => {
            const status = error.response.status;
            if (status === EResponseStatus.RES_STATUS_404) {
                setFormValidation(() => ({
                    isValid: false,
                    errorMessage: 'Участник не найден'
                }));
            } else {
                setFormValidation(() => ({
                    isValid: false,
                    errorMessage: 'Ошибка при генерации ссылки'
                }));
            }
        });
    };

    const errorMessageClassNames = cx({
        errorMessage: true,
        isValidLoginForm: !formValidation.isValid
    })

    return (
        <div className={styles.forgotPasswordPage}>
            <div className={styles.formWrapper}>
                {hasBeenSubmitted ? (
                    <div className={styles.successMessage}>
                        Ссылка на страницу сброса пароля была успешно отправлена!
                    </div>
                ) : (
                    <>
                        <Title size={ETitleSize.H3} weight={EFontWeight.REGULAR}>Сброс пароля</Title>
                        <Gap size={64} />
                        <Gap size={64} />
                        <Gap size={32} />
                        <form onSubmit={handleSubmit}>
                            <InputField
                                fieldName={'email'}
                                value={formState.email}
                                type={'text'}
                                label={'Почта'}
                                placeholder={'Почта'}
                                onChange={handleChange()}
                                isValid={formValidation.isValid}
                            />
                            <span className={errorMessageClassNames}>{formValidation.errorMessage}</span>
                            <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.SM} type="submit">
                                Сбросить пароль
                            </Button>
                        </form>
                    </>
                )}
                <Gap size={32}/>
                <Link linkType={ELinkType.TEXT} size={ELinkSize.LG} onClick={handleGoSignIn}>
                    На страницу входа
                </Link>
                </div>
        </div>

    )
}

ForgotPasswordPage.displayName = 'ForgotPasswordPage';
