import {getAuthToken} from "../Common/Utils";

export const useCheckJWT = () => {
    let flag: boolean;
    const token = getAuthToken()

    token ? flag = true : flag = false

    return flag
}
