import {IUser} from "../auth/types";

export interface IBestIndicators {
    speed: number;
    speedAverage: number;
    speedMax: number;
    accuracy: number;
    accuracyAverage: number;
    accuracyMax: number;
    time: number;
}

export interface ILeaderboardSaveResultRequest extends IBestIndicators {
    user: Pick<IUser, 'name' | 'surname' | 'login'>
}

export type TBestIndicators = Record<string, IBestIndicators>

// TODO: Поправить нейминг типов и начинку
export type SortParameter =
    keyof Exclude<LeaderboardRecord, 'user'>
    | keyof Pick<IUser, 'name' | 'surname' | 'login'>
    | 'place';

export enum SortDirection {
    ASCENDING = "ascending",
    DESCENDING = "descending",
    NONE = "none",
}

export type LeaderboardRecord = {
    user: Pick<IUser, 'name' | 'surname' | 'login'>
    speedMax: number;
    accuracyMax: number;
};

export interface ILeaderBoardListMeta {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    limit: number;
    nextpage: number | null;
    pagingCounter: number;
    prevPage: number | null;
    skip: number;
    totalPages: number | null;
}

export interface ILeaderboardRequest {
    sortBy: SortParameter;
    sortDirection: SortDirection;
    page: number;
}

export type ILeaderboardResponse = ILeaderBoardListMeta & {
    records: LeaderboardRecord[];
    totalCount: number;
};
