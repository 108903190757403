import React from "react";
import {useNavigate} from 'react-router-dom';
import {Link} from "../../Common/Components/Link/Link";
import {useSignOutUser} from "../../Common/Hooks/useSignOutUser";
import {HeaderPage} from "../../Common/Components/HeaderPage/HeaderPage";
import {LeaderboardTable} from "./LeaderboardTable";

export const LeaderboardPage: React.FC = () => {
    const navigate = useNavigate();
    const {handleSignOut} = useSignOutUser(navigate)

    return (
        <>
            <HeaderPage subtitle="Таблица лидеров" controls={[<Link title="Выход" onClick={handleSignOut}/>]} />
            <LeaderboardTable />
        </>
    );
};

LeaderboardPage.displayName = "LeaderboardPage";
