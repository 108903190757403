import {createBrowserRouter} from "react-router-dom";
import {MainPage} from "../Pages/MainPage/MainPage";
import {SignUpPage} from "../Pages/SignUpPage/SignUpPage";
import {SignInPage} from "../Pages/SignInPage/SignInPage";
import {LeaderboardPage} from "../Pages/LeaderboardPage/LeaderboardPage";
import {RouteGuard} from "../Core/RouteGuard/RouteGuard";
import {RegistrationSuccessPage} from "../Pages/RegistrationSuccessPage/RegistrationSuccessPage";
import {PracticePage} from "../Pages/PracticePage/PracticePage";
import {TrialPage} from "../Pages/TrialPage/TrialPage";
import {ChampionshipPage} from "../Pages/ChampionshipPage/ChampionshipPage";
import {ChampionshipRouteGuard} from "../Core/RouteGuard/ChampionshipRouteGuard";
import {ForgotPasswordPage} from "../Pages/ForgotPasswordPage/ForgotPasswordPage";
import {ResetPasswordPage} from "../Pages/ResetPasswordPage/ResetPasswordPage";

const BASE_URL = "/";

export const ROUTE_PATH = {
    MAIN: BASE_URL,
    SIGN_UP: `${BASE_URL}sign-up`,
    SIGN_IN: `${BASE_URL}sign-in`,
    TRAINER: `${BASE_URL}trainer`,
    LEADERBOARD: `${BASE_URL}leaderboard`,
    REGISTRATION_SUCCESS: `${BASE_URL}registration-success`,
    PROBE: `${BASE_URL}probe`,
    PRACTICE: `${BASE_URL}practice`,
    CHAMPIONSHIP: `${BASE_URL}championship`,
    LANDING_PAGE_TRIAL: `${BASE_URL}lp-trial`,
    FORGOT_PASSWORD: `${BASE_URL}forgot-password`,
    RESET_PASSWORD: `${BASE_URL}reset-password/:token`
};

export const router = createBrowserRouter([
    {
        path: ROUTE_PATH.MAIN,
        element: <MainPage/>,
    },
    {
        path: ROUTE_PATH.SIGN_UP,
        element: <SignUpPage/>,
    },
    {
        path: ROUTE_PATH.SIGN_IN,
        element: <SignInPage/>,
    },
    {
        path: ROUTE_PATH.LEADERBOARD,
        element: <LeaderboardPage/>,
    },
    {
        path: ROUTE_PATH.REGISTRATION_SUCCESS,
        element:
            <RouteGuard>
                <RegistrationSuccessPage/>
            </RouteGuard>,
    },
    {
        path: ROUTE_PATH.PRACTICE,
        element: <PracticePage />
    },
    {
        path: ROUTE_PATH.CHAMPIONSHIP,
        element:
            <ChampionshipRouteGuard>
                <ChampionshipPage />
            </ChampionshipRouteGuard>
    },
    {
        path: ROUTE_PATH.LANDING_PAGE_TRIAL,
        element: <TrialPage />
    },
    {
        path: ROUTE_PATH.FORGOT_PASSWORD,
        element: <ForgotPasswordPage />
    },
    {
        path: ROUTE_PATH.RESET_PASSWORD,
        element: <ResetPasswordPage />
    }
]);
