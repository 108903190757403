import React, {useState} from 'react';
import {RegistrationForm} from '../../Components/RegistrationForm/RegistrationForm';
import {IRegistrationFormData} from "../../Components/models";
import {ROUTE_PATH} from "../../Routes/Routes";
import {useNavigate} from "react-router-dom";
import {signUpUser} from "../../api/auth";
import {IUserRegistrationRequest} from "../../api/auth/types";
import {setAuthToken} from "../../Common/Utils";

import styles from './SignUpPage.module.css';

const initialData: IRegistrationFormData = {
    email: '',
    password: '',
    passwordRepeat: '',
    name: '',
    surname: '',
    login: ''
}

export const SignUpPage: React.FC = () => {
    const navigate = useNavigate();

    const [formValidation, setFormValidation] = useState({
        isValid: false,
        errorMessage: ''
    })

    const handleSignUp = (data: IUserRegistrationRequest) => signUpUser(data).then(async (res) => {
        if (res.status === 200) {
            const token = res.data.accessToken;
            setAuthToken(token);

            navigate(ROUTE_PATH.REGISTRATION_SUCCESS, {state: {prevPath: ROUTE_PATH.SIGN_UP}})
        }
    }).catch(error => {
        setFormValidation(prev => ({
            isValid: true,
            errorMessage: error.message
        }))
    });

    const handleGoSignIn = () => navigate(ROUTE_PATH.SIGN_IN);
    const handleGoLeaderboard = () => navigate(ROUTE_PATH.LEADERBOARD);

    return (
        <div className={styles.registrationPage}>
            <RegistrationForm
                initialData={initialData}
                onSubmit={handleSignUp}
                errorMessageForm={formValidation.errorMessage}
                isValidForm={formValidation.isValid}
                handleGoSignIn={handleGoSignIn}
                handleGoLeaderboard={handleGoLeaderboard}
            />
        </div>

    )
}

SignUpPage.displayName = 'SignUpPage';
