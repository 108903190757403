import React, {useState} from 'react';
import {ILoginFormData} from "../../Components/models";
import {LoginForm} from "../../Components/LoginForm/LoginForm";
import {ROUTE_PATH} from "../../Routes/Routes";
import {useNavigate} from "react-router-dom";
import {signInUser} from '../../api/auth';
import {EResponseStatus} from '../../api/types';
import {setAuthToken} from "../../Common/Utils";

import styles from './SignInPage.module.css';

const initialData: ILoginFormData = {
    login: '',
    password: '',
}

export const SignInPage: React.FC = () => {
    const navigate = useNavigate();

    const [formValidation, setFormValidation] = useState({
        isValid: true,
        errorMessage: ''
    })

    const handleSignIn = (data: any) => signInUser(data.login, data.password).then(async res => {
        const token = res.data.accessToken;
        setAuthToken(token)

        navigate(ROUTE_PATH.MAIN)
    }).catch(error => {
        const status = error.response.status;
        if (status === EResponseStatus.RES_STATUS_401) {
            setFormValidation(prev => ({
                isValid: false,
                errorMessage: 'Неверный логин или пароль'
            }));
        }
        if (status === EResponseStatus.RES_STATUS_404) {
            setFormValidation(prev => ({
                isValid: false,
                errorMessage: 'Участник не найден'
            }));
        }
    });

    const handleGoSignUp = () => navigate(ROUTE_PATH.SIGN_UP);
    const handleGoLeaderboard = () => navigate(ROUTE_PATH.LEADERBOARD);
    const handleGoTrainer = () => navigate(ROUTE_PATH.TRAINER)
    const handleGoForgotPassword = () => navigate(ROUTE_PATH.FORGOT_PASSWORD);

    return (
        <div className={styles.loginPage}>
            <LoginForm
                initialData={initialData}
                onSubmit={handleSignIn}
                isValidForm={formValidation.isValid}
                errorMessage={formValidation.errorMessage}
                handleGoSignUp={handleGoSignUp}
                handleGoLeaderboard={handleGoLeaderboard}
                handleGoTrainer={handleGoTrainer}
                handleGoForgotPassword={handleGoForgotPassword}
            />
        </div>

    )
}

SignInPage.displayName = 'SignInPage';
