import React from 'react'
import styles from "./HeaderPage.module.css";

type THeaderPage = {
    title?: string;
    subtitle?: string;
    controls?: JSX.Element[];
}

export const HeaderPage: React.FC<THeaderPage> = ({title, subtitle, controls}: THeaderPage) => {
    const renderControls = () => {
        return (
            <>
                {controls?.map((control) => (
                    <div className={styles.headerControlsItem}>
                        {control}
                    </div>
                ))}
            </>
        )
    }
    return (
        <div className={styles.header}>
            <div className={styles.titleWrapper}>
                <div className={styles.headerContent}>
                    <h1 className={styles.title}>{title}</h1>
                </div>
                <div className={styles.headerControls}>
                    {renderControls()}
                </div>
            </div>
            <div className={styles.subtitleWrapper}>
                <div className={styles.subtitle}>
                    <h3 className={styles.subtitleText}>
                        {subtitle}
                    </h3>
                </div>
            </div>
        </div>
    )
}