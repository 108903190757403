import {NavigateFunction} from "react-router-dom";
import {signOutUser} from "../../api/auth";
import {setAuthToken} from "../Utils";
import {ROUTE_PATH} from "../../Routes/Routes";

export const useSignOutUser = (navigate: NavigateFunction) => {
    const handleSignOut = () => signOutUser().then(async res => {
        setAuthToken('');
        navigate(ROUTE_PATH.SIGN_IN);
    })

    return {handleSignOut}
}
