import React from 'react'
import {EButtonStyle} from "../../Enums";
import classNames from "classnames/bind";

import styles from './Button.module.css';

const cx = classNames.bind(styles);

/**
 * Кнопка.
 *
 * @prop type Тип кнопки.
 * @prop title Надпись кнопки.
 * @prop [onClick] Обработчик нажатия.
 */
interface IProps {
    type: EButtonStyle;
    title: string;
    onClick?: () => void;
}

export const Button: React.FC<IProps> = ({type, title, onClick}: IProps) => {
    const buttonStyles = cx({
        button: true,
        primary: type === EButtonStyle.PRIMARY,
        secondary: type === EButtonStyle.SECONDARY
    })

    return (
        <button className={buttonStyles} onClick={onClick}>
            {title}
        </button>
    )
}

Button.displayName = 'Button'
