import React, {useState} from 'react'
import {ILoginFormData} from "../models";
import {InputField} from "../../Common/Components/InputField/InputField";
import {Gap} from "@sberbusiness/triplex/components/Gap/Gap";
import {EButtonSize, EButtonTheme} from "@sberbusiness/triplex/components/Button/enums";
import {Button} from "@sberbusiness/triplex/components/Button/Button";
import {ELinkSize, ELinkType, Link} from "@sberbusiness/triplex/components/Link/Link";
import {Title} from "@sberbusiness/triplex/components/Typography/Title";
import {EFontWeight, ETitleSize} from "@sberbusiness/triplex/components/Typography/enums";
import {validatePasswordLength} from "../utils";
import {FormGroup, FormGroupLine} from "@sberbusiness/triplex/components/FormGroup";
import {FormFieldDescription} from "@sberbusiness/triplex/components/FormField";

import styles from "./LoginForm.module.css";

/**
 * Компонент Формы авторизации.
 *
 * @prop initialData Начальные данные.
 * @prop onSubmit Обработчик формы.
 * @prop isValidForm Флаг валидности данных, получаемый после отправки формы.
 * @prop errorMessage Текст об ошибки валидации формы.
 * @prop handleGoSignUp Обработчик кнопки Регистрация.
 * @prop handleGoLeaderboard Обработчик кнопки Таблица лидеров.
 * @prop handleGoTrainer Обработчик кнопки Попробовать.
 */
interface IProps {
    initialData: ILoginFormData;
    onSubmit: (data: any) => void;
    isValidForm: boolean;
    errorMessage: string;
    handleGoSignUp: () => void;
    handleGoLeaderboard: () => void;
    handleGoTrainer: () => void;
    handleGoForgotPassword: () => void;
}

export const LoginForm: React.FC<IProps> = ({
    initialData,
    onSubmit,
    isValidForm,
    errorMessage,
    handleGoSignUp,
    handleGoLeaderboard,
    handleGoTrainer,
    handleGoForgotPassword
}: IProps) => {
    const [formState, setFormState] = useState(initialData);

    const [invalidFields, setInvalidFields] = useState(Object.fromEntries(
        Object.entries(initialData).map(([key]) => [key, true])
    ));

    const errorFormMessage = () => {
        return (fieldName: string, fieldValue: string): string => {
            if (fieldName === 'password' && validatePasswordLength(fieldValue)) {
                return 'Минимум 8 символов'
            }

            if (!fieldValue) {
                return 'Обязательное поле'
            }

            return '';
        }
    }

    const setInvalidFieldsState = (fieldName: string, hasValidateFieldValue: boolean) => {
        setInvalidFields(prevState => ({
            ...prevState,
            [fieldName]: hasValidateFieldValue
        }))
    }

    const handleChange = () => (fieldName: string) => (fieldValue: string) => {
        setFormState({
            ...formState,
            [fieldName]: fieldValue,
        });

        const hasValidateFieldValue = fieldName === 'password' ? !!fieldValue && !validatePasswordLength(fieldValue) : !!fieldValue

        setInvalidFieldsState(fieldName, hasValidateFieldValue)
    };

    const handleSubmit = () => {
        const hasErrors = Object.values(invalidFields).includes(false);
        const hasData = Object.values(formState).every((item) => !!item);

        Object.entries(formState).forEach(([field, value]) => {
            !value && setInvalidFieldsState(field, false)
        })

        if (!hasErrors && hasData) {
            onSubmit(formState)
        }
    };

    return (
        <div className={styles.FormWrapper}>
            <Title size={ETitleSize.H3} weight={EFontWeight.REGULAR}>Вход</Title>
            <Gap size={64} />
            <Gap size={64} />
            <Gap size={32} />
            <InputField
                fieldName={'login'}
                placeholder={'ivanov@email.ru'}
                value={formState.login}
                type={'text'}
                label={'Ник или почта'}
                onChange={handleChange()}
                isValid={invalidFields['login']}
                errorMessage={errorFormMessage()}
                autoComplete='username'
            />
            <Gap size={16} />
            <InputField
                fieldName={'password'}
                placeholder={'Введите пароль'}
                value={formState.password}
                type={'password'}
                label={'Пароль'}
                onChange={handleChange()}
                isValid={invalidFields['password']}
                errorMessage={errorFormMessage()}
                autoComplete='current-password'
            />
            {(!!errorMessage && !isValidForm) && (
                <div className={styles.Description}>
                    <FormGroup>
                        <FormGroupLine>
                            <FormFieldDescription error>{errorMessage}</FormFieldDescription>
                        </FormGroupLine>
                    </FormGroup>
                </div>
            )}
            <div className={styles.Buttons}>
                <Gap size={32} />
                <Gap size={16} />
                <Button
                    theme={EButtonTheme.GENERAL}
                    size={EButtonSize.SM}
                    onClick={handleSubmit}
                    block
                >
                    Войти
                </Button>
                <Gap size={16} />
                <Link linkType={ELinkType.TEXT} size={ELinkSize.LG} onClick={handleGoForgotPassword}>
                    Забыли пароль?
                </Link>
                <Gap size={64}/>
                <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.SM} onClick={handleGoSignUp} block>
                    Регистрация
                </Button>
                <Gap size={64}/>
                <Link linkType={ELinkType.TEXT} size={ELinkSize.LG} onClick={handleGoLeaderboard}>
                    Таблица лидеров
                </Link>
                <Gap size={64}/>
                <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.SM} onClick={handleGoTrainer} block>
                    Попробовать
                </Button>
            </div>
        </div>
    )
}

LoginForm.displayName = 'LoginForm'
