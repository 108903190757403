import React, {useCallback, useContext, useEffect, useState} from "react";
import {useKeydownEvent} from "../../Common/Hooks/useKeydownEvent";
import Simulator from "./Components/Simulator/Simulator";
import {useNavigate} from "react-router-dom";
import {useInterval} from "./utils";
import ResultsInfo from "./Components/ResultsInfo/ResultsInfo";
import {EKeyboardSimulatorTypes} from "./enums";
import {Button} from "@sberbusiness/triplex/components/Button/Button";
import {EButtonSize, EButtonTheme} from "@sberbusiness/triplex/components/Button/enums";
import {ROUTE_PATH} from "../../Routes/Routes";
import {getAuthToken, getAverage} from "../../Common/Utils";
import jwt_decode from "jwt-decode";
import {IChampionshipResultSendRq} from "../../api/championship/types";
import {sendChampionshipResultByUser} from "../../api/championship";
import {AppSettingsContext} from "../../Core/Contexts/appSettings";

import styles from "./KeyboardSimulator.module.css";

/**
 * Симулятор клавиатуры
 *
 * @prop keyboardText Текст для симулятора клавиатуры
 */
interface IProps {
    keyboardText: string;
    type: EKeyboardSimulatorTypes

}

export const KeyboardSimulator: React.FC<IProps> = ({keyboardText, type}: IProps) => {
    const navigate = useNavigate();
    const appSettings = useContext(AppSettingsContext)

    const [textForTest, setTextForTest] = useState('');
    const [enteredLettersCount, setEnteredLettersCount] = useState(0);
    const [isStartButtonPressed, setIsStartButtonPressed] = useState(false);
    const [mistakeCount, setMistakeCount] = useState(0);
    const [isSameMistake, setIsSameMistake] = useState(false);
    const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
    const [textForUser, setTextForUser] = useState(textForTest);
    const [accuracy, setAccuracy] = useState(100);
    const [maxAccuracy, setMaxAccuracy] = useState(100);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isStart, setIsStart] = useState(false);
    const [isRestart, setIsRestart] = useState(false);
    const [passedTime, setPassedTime] = useState(1);
    const [speedPrint, setSpeedPrint] = useState(0);
    const [maxSpeedPrint, setMaxSpeedPrint] = useState(0);

    const [isSavingResult, setIsSavingResult] = useState<boolean>(false)
    const [isFailedSavingResult, setIsFailedSavingResult] = useState<boolean>(false)

    useInterval(() => {
        if (isStart) {
            setPassedTime(passedTime + 1);
            setSpeedPrint(Math.floor((enteredLettersCount * 60) / passedTime));
        }
    }, 1000);

    useEffect(() => {
        if (speedPrint > 0) {
            setMaxSpeedPrint(Math.max(speedPrint, maxSpeedPrint))
        }
    }, [speedPrint, maxSpeedPrint])

    useEffect(() => {
        setTextForTest(keyboardText)
    }, [keyboardText])

    if (
        !isCompleted &&
        enteredLettersCount &&
        enteredLettersCount === textForTest.length
    ) {
        setIsCompleted(true);
    }

    const markedLetter = useCallback(
        (index: number, color: string) => {
            if (!isCompleted) {
                const arr = textForTest.split("");
                arr[
                    index
                    ] = `<span style="color: white; background: ${color}">${textForTest[index]}</span>`;
                setTextForUser(arr.join(""));
            }

        },
        [textForTest, isCompleted]
    );

    const checkedValid = (evt: KeyboardEvent) => {
        if (!isStartButtonPressed && evt.code === 'Space') {
            setIsStartButtonPressed(true)
        }

        if (isStartButtonPressed) {
            if (evt.key.length === 1 && evt.key === textForTest[currentLetterIndex]) {
                if (isSameMistake) {
                    setIsSameMistake(false);
                }

                setCurrentLetterIndex((currentLetterIndex) => currentLetterIndex + 1);
                setEnteredLettersCount((enteredLettersCount) => enteredLettersCount + 1);
                markedLetter(currentLetterIndex + 1, "#2E81AF");
            } else if (evt.key.length === 1 && !isSameMistake) {
                markedLetter(currentLetterIndex, "red");
                setIsSameMistake(true);

                const newAccuracy = accuracy - 100 / textForTest.length;
                setAccuracy(Math.floor(10 * newAccuracy) / 10);
            }
        }
    };

    useEffect(() => {
        if (accuracy) {
            setMaxAccuracy(Math.max(accuracy, maxAccuracy))
        }
    }, [accuracy, maxAccuracy])

    useEffect(() => {
        if (isStartButtonPressed && !isCompleted) {
            setIsStart(true);
        } else {
            setIsStart(false);
        }
    }, [isStartButtonPressed, isCompleted]);

    useEffect(() => {
        markedLetter(0, "#2E81AF");
    }, [markedLetter]);

    useKeydownEvent("keydown", checkedValid);

    const handleRestart = useCallback(() => {
        setEnteredLettersCount(0);
        setCurrentLetterIndex(0);
        setMistakeCount(0);
        setTextForUser("");
        setAccuracy(100);
        setIsCompleted(false);
        setIsRestart(true);
        setIsStartButtonPressed(false)
        setPassedTime(1)
        setSpeedPrint(0)
        setMaxSpeedPrint(0)
    }, [isRestart]);

    const handleFinish = () => {
        navigate(ROUTE_PATH.MAIN)
    }

    const handleSaveResult = () => {
        setIsSavingResult(true)
        const token = getAuthToken();
        const userData: any = token && jwt_decode(token);

        const averageSpeedPrint = getAverage([speedPrint, maxSpeedPrint])
        const averageAccuracy = getAverage([accuracy, maxAccuracy])

        const testData: IChampionshipResultSendRq = {
            stageNumber: appSettings?.currentChampionshipRound,
            user: {
                login: userData.sub,
            },
            result: {
                speedAverage: averageSpeedPrint,
                speedMax: maxSpeedPrint,
                accuracyAverage: averageAccuracy,
                time: passedTime
            }

        }

        sendChampionshipResultByUser(testData)
            .then(() => navigate(ROUTE_PATH.MAIN))
            .catch(() => setIsFailedSavingResult(true))
            .finally(() => setIsSavingResult(false))
    }

    const renderBody = () => {
        if (isCompleted) {
            return (
                <ResultsInfo
                    speedPrint={speedPrint}
                    accuracy={accuracy}
                    passedTime={passedTime}
                />
            )
        } else {
            return (
                <Simulator
                    speedPrint={speedPrint}
                    textForUser={textForUser}
                    isStart={isStart}
                    accuracy={accuracy}
                />
            )
        }
    }

    const renderButtons = () => {
        if (type === EKeyboardSimulatorTypes.CHAMPIONSHIP) {
            if (isCompleted) {
                return (
                    <>
                        <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.MD} onClick={handleRestart}>Начать заново</Button>
                        <Button loading={isSavingResult} theme={EButtonTheme.GENERAL} size={EButtonSize.MD} onClick={handleSaveResult}>Закончить</Button>
                    </>
                )
            } else {
                return (
                    <>
                        <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.MD} onClick={handleFinish}>Закончить</Button>
                    </>
                )
            }
        }

        if (type === EKeyboardSimulatorTypes.PRACTICE) {
            if (isCompleted) {
                return (
                    <>
                        <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.MD} onClick={handleRestart}>Начать заново</Button>
                        <Button theme={EButtonTheme.GENERAL} size={EButtonSize.MD} onClick={handleFinish}>Закончить</Button>
                    </>
                )
            }
            else {
                return (
                    <>
                        <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.MD} onClick={handleRestart}>Начать заново</Button>
                        <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.MD} onClick={handleFinish}>Закончить</Button>
                    </>
                )
            }
        }
    }

    return (
        <div className={styles.trainerPageWrapper}>
            {renderBody()}
            <div className={styles.keyboardSimulatorButtonGroup}>
                {renderButtons()}
            </div>
        </div>
    );
}
