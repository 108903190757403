// Достаём accessToken из сессионного хранилища.
export const getAuthToken = () => sessionStorage.getItem('token');

/**
 * Сохраняем accessToken в сессионное хранилище.
 * @param value Новое значение accessToken.
 */
export const setAuthToken = (value: string) => {
    if (value == undefined) {
        sessionStorage.setItem('token', '')
    } else {
        sessionStorage.setItem('token', value)
    }
}

/**
 * Получение имени пользователя.
 *
 * @param name Имя пользователя.
 * @param surname Фамилия пользователя.
 * @param isShortFormat Флаг короткой записи Имя Ф. .
 */
export const getFullName = (name: string, surname: string, isShortFormat?: boolean) => {
    return isShortFormat ? `${name} ${surname.charAt(0)}.` : `${name} ${surname}`
}

/**
 * Получение среднего значения.
 * @param nums Массив значений.
 * @returns Возвращает среднее значение среди переданных.
 */
export const getAverage = (nums: number[]): number => nums.reduce((a, b) => (a + b)) / nums.length