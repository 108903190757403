import React from "react";
import { ITableBasicColumn } from '@sberbusiness/triplex/components/Tables/TableBasic/types';
import { TableCell } from '../../../../Common/Components/TableCell/TableCell';
import { MasterTable } from '@sberbusiness/triplex/components/Tables/MasterTable';
import { Text } from '@sberbusiness/triplex/components/Typography/Text';
import { ETextSize } from '@sberbusiness/triplex/components/Typography/enums';
import { IChampionship } from '../../../../api/championship/types';

import styles from './ResultsTable.module.css';

/**
 * Кнопка
 *
 * @prop resultsData Результаты пользователя
 * @prop isLoadingData Флаг загрузки данных
 */
interface IProps {
    resultsData: IChampionship[];
    isLoadingData: boolean;
}

export const ResultsTable: React.FC<IProps> = ({resultsData, isLoadingData}: IProps) => {


    const columns: ITableBasicColumn[] = [
        {
            fieldKey: 'stage',
            label: 'Этап',
            width: 80,
            renderCell: (value: string) => <TableCell value={value} isLoading={isLoadingData} />
        },
        {
            fieldKey: 'time',
            label: 'Время',
            width: 160,
            renderCell: (value: string) => <TableCell value={value} isLoading={isLoadingData} />
        },
        {
            fieldKey: 'speed',
            label: 'Скорость',
            width: 300,
            renderCell: (value: string) => <TableCell value={value} isLoading={isLoadingData} />
        },
        {
            fieldKey: 'accuracy',
            label: 'Точность',
            width: 150,
            renderCell: (value: string) => <TableCell value={value} isLoading={isLoadingData} />
        },
        {
            fieldKey: 'score',
            label: 'Очки',
            width: 150,
            renderCell: (value: string) => <TableCell value={value} isLoading={isLoadingData} />
        },
    ];

    const formattedData = (data: IChampionship[]) => {
        return data.map((item, idx) => ({
            rowKey: `table-basic-row-${item?.user?.login}-${idx}`,
            rowData: {
                stage: item.stageNumber,
                time: item.result.time,
                speed: item.result.speedAverage,
                accuracy: item.result.accuracyAverage,
                score: item.result.score || '---',
            }
        }))
    }

    return (
        <div className={styles.resultTable}>
            <MasterTable>
                <MasterTable.TableBasic
                    columns={columns}
                    data={formattedData(resultsData)}
                    renderNoData={() => <Text size={ETextSize.B1}>Нет данных</Text>}
                />
            </MasterTable>
        </div>
    );
};

ResultsTable.displayName = "ResultsTable";
