import {AxiosResponse} from "axios";
import axiosInstance from "../index";
import {
    ILeaderboardRequest,
    ILeaderboardResponse,
    ILeaderboardSaveResultRequest, TBestIndicators,
} from "./types";
import {useInfiniteQuery, useMutation, useQuery} from '@tanstack/react-query'

export const saveResult = (body: ILeaderboardSaveResultRequest): Promise<AxiosResponse<any>> => {
    return axiosInstance.post('/leaderboard/saveResult', body)
}

const fetchLeaderboardData = async ({sortBy, sortDirection, page}: ILeaderboardRequest) => {
    const result = await axiosInstance.get<ILeaderboardResponse>(`/leaderboard/`, {
        params: {
            sortBy,
            sortDirection,
            page
        }
    });

    return result.data;
}

export const useLeaderboardSaveResult = () => useMutation(
    ['LEADERBOARD_SAVE_RESULT'],
    (body: ILeaderboardSaveResultRequest) => saveResult(body),
)

export const useLeaderboardTableData = ({ sortBy, sortDirection, page }: ILeaderboardRequest) => {
    return useQuery(
        ['LEADERBOARD_LIST_Q', page, sortBy, sortDirection],
        () => fetchLeaderboardData({sortBy, sortDirection, page}),
        { keepPreviousData : true }
    )
}

export const fetchBestIndicators = async (): Promise<AxiosResponse<TBestIndicators>> => {
    return await axiosInstance.get('/best-indicators');
}
