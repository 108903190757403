import React, {useContext, useEffect, useState} from 'react';
import {useLocation, Navigate} from 'react-router-dom';
import {ROUTE_PATH} from "../../Routes/Routes";
import {AppSettingsContext} from "../Contexts/appSettings";
import {getTextForChampionshipByStage} from "../../api/keyboardText";
import {fetchUserResults} from "../../api/championship";
import {getAuthToken} from "../../Common/Utils";
import jwt_decode from "jwt-decode";

export const ChampionshipRouteGuard = ({children}: { children: JSX.Element }) => {
    const location = useLocation();
    const appSettings = useContext(AppSettingsContext)

    const [isEnableByAttemptsCounter, setIsEnableByAttemptsCounter] = useState(true)

    const token = getAuthToken();
    const userData: any = token && jwt_decode(token);

    useEffect(() => {
        fetchUserResults({login: userData?.sub}).then((res) => {
            const roundAttempts = res.results.find((result) => result.stageNumber === appSettings?.currentChampionshipRound)?.attemptsCounter

            if (roundAttempts && appSettings && roundAttempts >= appSettings.maxAttemptsCounterForRound) {
                setIsEnableByAttemptsCounter(false)
            }
        })

    }, []);

    if (appSettings?.isEnableChampionship && isEnableByAttemptsCounter) {
        return children
    } else {
        return <Navigate to={ROUTE_PATH.MAIN} state={{from: location}} replace/>;
    }
}

ChampionshipRouteGuard.displayName = 'ChampionshipRouteGuard'
