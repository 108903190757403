import React from 'react';
import {useLocation, Navigate} from 'react-router-dom';
import {useCheckJWT} from "../../Hooks/useCheckJWT";
import {ROUTE_PATH} from "../../Routes/Routes";

export const RouteGuard = ({children}: { children: JSX.Element }) => {
    const location = useLocation();
    const hasJWT = useCheckJWT()

    if (location.pathname === ROUTE_PATH.REGISTRATION_SUCCESS) {
        if (!hasJWT && location.state?.prevPath === ROUTE_PATH.SIGN_UP) {
            return children
        }
        return <Navigate to={ROUTE_PATH.MAIN} state={{from: location}} replace/>;

    } else {
        if (!hasJWT) {
            return <Navigate to={ROUTE_PATH.MAIN} state={{from: location}} replace/>;
        } else {
            return children;
        }
    }
}
