import React from 'react'

import styles from './Link.module.css';

/**
 * Компонент Ссылка.
 *
 * @prop title Надпись ссылки.
 * @prop onClick Обработчик нажатия.
 */
interface IProps {
    title: string;
    onClick: () => void;
}

export const Link: React.FC<IProps> = ({title, onClick}: IProps) => (
    <div className={styles.linkMain} onClick={onClick}>
        {title}
    </div>
)

Link.displayName = 'Link'
