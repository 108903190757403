import React from 'react'
import {Button} from '../Button/Button';
import {EButtonStyle} from '../../Enums';

import styles from './Modal.module.css';

interface ModalProps {
    isOpen: boolean;
    contentText: string;
    btnAction: () => void;
    btnActionText: string;
}

/**
 * Компонент модального окна.
 *
 * @prop isOpen флаг открытия модалки.
 * @prop contentText текст модалки.
 * @prop btnAction функция для кнопки в модалке.
 * @prop btnActionText текст для кнопки в модалке.
 */
export const Modal: React.FC<ModalProps> = ({
    isOpen,
    contentText,
    btnAction,
    btnActionText,
}) => {

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.modalWrapper}>
            <div className={styles.modalContent}>
                <div className={styles.modalContentText}>{contentText}</div>
                <div className={styles.modalContentBtnWrapper}>
                    <Button
                        title={btnActionText}
                        type={EButtonStyle.PRIMARY}
                        onClick={btnAction}
                    />
                </div>
            </div>
        </div>
    )
}
