import axiosInstance from "../index";
import {IKeyboardTextResponse} from "./types";

export const getKeyboardTextByLevel = async (level: number) => {
    return axiosInstance.get<IKeyboardTextResponse>(`/keyboardText/${level}`)
}

export const getKeyboardTextByLevelForTests = async (level: number, role?: string) => {
    return axiosInstance.get<IKeyboardTextResponse>(`/keyboardText/${level}/${role}`)
}

export const getTextForPractice = async () => {
    return axiosInstance.get<IKeyboardTextResponse>(`/keyboard-text-practice`)
}

export const getTextForChampionshipByStage = async (stage: number) => {
    return axiosInstance.get<IKeyboardTextResponse>(`/keyboard-text-championship/${stage}`)
}
