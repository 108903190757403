import React from "react";

import styles from './ResultsInfo.module.css'

type WinnerScreenProps = {
    passedTime?: number,
    accuracy?: number,
    speedPrint?: number,
}

export default function ResultsInfo({
        passedTime,
        accuracy,
        speedPrint,
    }: WinnerScreenProps): JSX.Element {

    return (
        <div>
            {passedTime && accuracy && speedPrint &&
                <>
                    <h1 className={styles.winnerScreenHeader}>Результат</h1>
                    <div className={styles.winnerScreenResults}>
                        <div className={styles.winnerScreenTime}>
                            <p>Время:</p>
                            <p>{passedTime} сек.</p>
                        </div>
                        <div className={styles.winnerScreenSpeed}>
                            <p>Скорость печати:</p>
                            <p><span>{Math.floor(speedPrint)}</span> зн./минуту</p>
                        </div>
                        <div className={styles.winnerScreenAccuracy}>
                            <p>Процент точности:</p>
                            <p>{accuracy} %</p>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
