import React, {useEffect, useState} from 'react'
import {IRegistrationFormData} from "../models";
import {InputField} from "../../Common/Components/InputField/InputField";
import {
    validateEqualPasswords,
    validateField,
    validatePasswordLength,
    validateStringRegExp
} from "../utils";
import {Gap} from "@sberbusiness/triplex/components/Gap/Gap";
import {EButtonSize, EButtonTheme} from "@sberbusiness/triplex/components/Button/enums";
import {Button} from "@sberbusiness/triplex/components/Button/Button";
import {FormFieldDescription} from "@sberbusiness/triplex/components/FormField";
import {FormGroup, FormGroupLine} from "@sberbusiness/triplex/components/FormGroup";
import {EFontWeight, ETitleSize} from "@sberbusiness/triplex/components/Typography/enums";
import {Title} from "@sberbusiness/triplex/components/Typography/Title";
import {ELinkSize, ELinkType, Link} from "@sberbusiness/triplex/components/Link/Link";

import styles from './RegistrationForm.module.css';

/**
 * Компонент Формы регистрации.
 *
 * @prop initialData Начальные данные.
 * @prop onSubmit Обработчик формы.
 * @prop isValidForm Флаг валидности данных, получаемый после отправки формы.
 * @prop errorMessageForm Текст об ошибки валидации формы.
 * @prop handleGoSignIn Обработчик кнопки Вход.
 * @prop handleGoLeaderboard Обработчик кнопки Таблица лидеров.
 */
interface IProps {
    initialData: IRegistrationFormData;
    onSubmit: (data: any) => void;
    isValidForm: boolean;
    errorMessageForm: string;
    handleGoSignIn: () => void;
    handleGoLeaderboard: () => void;
}

export const RegistrationForm: React.FC<IProps> = ({
    initialData,
    onSubmit,
    isValidForm,
    errorMessageForm,
    handleGoSignIn,
    handleGoLeaderboard,
}: IProps) => {
    const [formState, setFormState] = useState(initialData);

    const [invalidFields, setInvalidFields] = useState(Object.fromEntries(
        Object.entries(initialData).map(([key]) => [key, true])
    ));

    useEffect(() => {
        setInvalidFields(prevState => ({
            ...prevState,
            password: !(validateEqualPasswords(formState.password, formState.passwordRepeat) || validatePasswordLength(formState.password)),
            passwordRepeat: !(validateEqualPasswords(formState.password, formState.passwordRepeat) || validatePasswordLength(formState.password)),
        }))
    }, [formState.password, formState.passwordRepeat])

    const errorMessage = () => {
        return (fieldName: string, fieldValue: string): string => {
            if (!fieldValue) {
                return 'Обязательное поле'
            }
            switch (fieldName) {
                case 'name':
                case 'surname':
                    if (validateStringRegExp(fieldValue)) {
                        return 'Для поля недопустимые цифры и спецсимволы'
                    }
                    break;
                case 'email':
                    return 'Не подходит под формат E-mail'
                case 'password':
                case 'passwordRepeat':
                    if (validatePasswordLength(fieldValue)) {
                        return 'Минимум 8 символов'
                    }
                    return 'Пароли не совпадают'
            }
            return '';
        }
    }

    const setInvalidFieldsState = (fieldName: string, hasValidateFieldValue: boolean) => {
        setInvalidFields(prevState => ({
            ...prevState,
            [fieldName]: hasValidateFieldValue
        }))
    }

    const handleChange = () => (fieldName: string) => (fieldValue: string) => {
        setFormState({
            ...formState,
            [fieldName]: fieldValue,
        });

        const hasValidateFieldValue = validateField(fieldName, fieldValue)

        setInvalidFieldsState(fieldName, hasValidateFieldValue)
    };
    const handleSubmit = () => {
        const hasErrors = Object.values(invalidFields).includes(false);
        const hasData = Object.values(formState).every((item) => !!item);

        Object.entries(formState).forEach(([field, value]) => {
            setInvalidFieldsState(field, !!value)
        })

        !hasErrors && hasData && onSubmit(formState)
    };

    return (
        <div className={styles.FormWrapper}>
            <Title size={ETitleSize.H3} weight={EFontWeight.REGULAR}>Регистрация</Title>
            <Gap size={64} />
            <InputField
                fieldName={'name'}
                placeholder={'Иван'}
                value={formState.name}
                type={'text'}
                label={'Имя'}
                onChange={handleChange()}
                isValid={invalidFields['name']}
                errorMessage={errorMessage()}
                autoComplete='given-name'
            />
            <Gap size={16} />
            <InputField
                fieldName={'surname'}
                placeholder={'Иванов'}
                value={formState.surname}
                type={'text'}
                label={'Фамилия'}
                onChange={handleChange()}
                isValid={invalidFields['surname']}
                errorMessage={errorMessage()}
                autoComplete='family-name'
            />
            <Gap size={16} />
            <InputField
                fieldName={'email'}
                placeholder={'ivanov@email.ru'}
                value={formState.email}
                type={'text'}
                label={'E-mail'}
                onChange={handleChange()}
                isValid={invalidFields['email']}
                errorMessage={errorMessage()}
                autoComplete='email'
            />
            <Gap size={16} />
            <InputField
                fieldName={'login'}
                placeholder={'ivan'}
                value={formState.login}
                type={'text'}
                label={'Ник'}
                onChange={handleChange()}
                isValid={invalidFields['login']}
                errorMessage={errorMessage()}
                autoComplete='username'
            />
            <Gap size={16} />
            <InputField
                fieldName={'password'}
                placeholder={'Введите пароль'}
                value={formState.password}
                type={'password'}
                label={'Пароль'}
                onChange={handleChange()}
                isValid={invalidFields['password']}
                errorMessage={errorMessage()}
                autoComplete='new-password'
            />
            <Gap size={16} />
            <InputField
                fieldName={'passwordRepeat'}
                placeholder={'Повторите пароль'}
                value={formState.passwordRepeat}
                type={'password'}
                label={'Пароль ещё раз'}
                onChange={handleChange()}
                isValid={invalidFields['passwordRepeat']}
                errorMessage={errorMessage()}
                autoComplete='new-password'
            />
            {(!!errorMessageForm && !isValidForm) && (
                <div className={styles.Description}>
                    <FormGroup>
                        <FormGroupLine>
                            <FormFieldDescription error>{errorMessageForm}</FormFieldDescription>
                        </FormGroupLine>
                    </FormGroup>
                </div>
            )}
            <div className={styles.Buttons}>
                <Button theme={EButtonTheme.GENERAL} size={EButtonSize.SM} onClick={handleSubmit} block>Зарегистрироваться</Button>
                <Gap size={64} />
                <Button theme={EButtonTheme.SECONDARY} size={EButtonSize.SM} onClick={handleGoSignIn} block>
                    Вход
                </Button>
                <Gap size={64} />
                <Link linkType={ELinkType.TEXT} size={ELinkSize.LG} onClick={handleGoLeaderboard}>
                    Таблица лидеров
                </Link>
            </div>
        </div>
    )
}

RegistrationForm.displayName = 'RegistrationForm'
