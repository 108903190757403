import {INPUT_PASSWORD_VALUE_MINIMUM_LENGTH} from "./consts";

// Проверка длины пароля на минимальное значение.
export const validatePasswordLength = (value?: string) => value && value.trim().length < INPUT_PASSWORD_VALUE_MINIMUM_LENGTH;

// Проверка идентичности паролей.
export const validateEqualPasswords = (value1: string, value2: string) => value1 !== value2;

// Проверка строки на вхождение спецсимволов.
export const validateStringRegExp = (value: string) => {
    const result = value.trim().match(/[-a-zA-Zа-яА-ЯёЁ\s]/g);

    return result?.length !== value.trim().length
}
// Проверка строки на вхождение спецсимволов.
export const validateNicknameRegExp = (value: string) => {
    const result = value.trim().match(/[-a-zA-Zа-яА-ЯёЁ0-9\,.@%^*()₽\s]/g);

    return result?.length !== value.trim().length
}

// Валидация поля с email.
export const validateEmail = (value: string) => {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return !reg.test(value)
}

// Валидация полей по названию поля
export const validateField = (fieldName: string, fieldValue: string): boolean => {
    switch (fieldName) {
        case 'name':
        case 'surname':
            return !(validateStringRegExp(fieldValue))
        case 'email':
            return !validateEmail(fieldValue)
        case 'nickname':
            return !(validateNicknameRegExp(fieldValue))
        case 'password':
        case 'passwordRepeat':
            return false
        default:
            return true
    }
}
