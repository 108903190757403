import {Keyboard} from "../../../../Common/Components/Keyboard/Keyboard";

import styles from "./Simulator.module.css";

type TClassicGameScreenProps = {
    textForUser: string;
    accuracy: number;
    speedPrint: number;
    isStart: boolean;
};

export default function Simulator({
    textForUser,
    isStart,
    accuracy,
    speedPrint
}: TClassicGameScreenProps): JSX.Element {

    // const handleSaveResult = useCallback(() => {
    //     const token = getAuthToken();
    //     const userData: any = token && jwt_decode(token);
    //
    //     const averageSpeedPrint = getAverage([speedPrint, maxSpeedPrint])
    //     const averageAccuracy = getAverage([accuracy, maxAccuracy])
    //
    //     const testData: ILeaderboardSaveResultRequest = {
    //         user: {
    //             name: userData.name,
    //             surname: userData.surname,
    //             login: userData.login
    //         },
    //         speed: speedPrint,
    //         speedAverage: averageSpeedPrint,
    //         speedMax: maxSpeedPrint,
    //         accuracy: accuracy,
    //         accuracyAverage: averageAccuracy,
    //         accuracyMax: maxAccuracy,
    //         time: passedTime
    //     }
    //
    //     saveResult(testData)
    // }, [accuracy, maxAccuracy, maxSpeedPrint, passedTime, saveResult, speedPrint]);

    return (
        <div className={styles.printingScreenWrapper}>
            <div className={styles.printingScreenResults}>
                <div className={styles.printingScreenSpeed}>
                    <span className={styles.printingScreenSpeedLabel}>Скорость</span>
                    <span className={styles.printingScreenSpeedValue}>
            {Math.floor(speedPrint)} зн./минуту
          </span>
                </div>
                <div className={styles.printingScreenAccuracy}>
                    <span className={styles.printingScreenAccuracyLabel}>Точность</span>
                    <span className={styles.printingScreenAccuracyValue}>{accuracy} %</span>
                </div>
            </div>
            <div className={styles.printingScreenText}>
        <span
            id="text"
            dangerouslySetInnerHTML={{__html: textForUser}}
        ></span>
            </div>
            <Keyboard />

            {!isStart && (
                <div className={styles.printingScreenBeforeStart}>
                    <h2>Для начала соревнования, нажмите клавишу пробел.</h2>
                </div>
            )}
        </div>
    );
}
