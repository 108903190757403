import axiosInstance from "../index";
import {
    IChampionshipResultsByUserRq,
    IChampionshipResultsByUserRs, IChampionshipResultSendRq, IChampionshipResultSendRs
} from "./types";

/**
 * Получение результатов пользователя в чемпионате
 */
export const fetchUserResults = async (body: IChampionshipResultsByUserRq) => {
    const {data: response} = await axiosInstance.get<IChampionshipResultsByUserRs>(`/championship/getResultsByUserId`, {
        params: {
            login: body.login,
        }
    });
    return response;
}

/**
 * Отправка результатов чемпионата
 */
export const sendChampionshipResultByUser = async (body: IChampionshipResultSendRq) => {
    const {data: response} = await axiosInstance.post<IChampionshipResultSendRs>(`/championship/save-championship-result`, body);
    return response;
}
