import React, { useContext, useEffect, useState} from 'react'
import {getTextForChampionshipByStage} from "../../api/keyboardText";
import {IKeyboardText} from "../../Common/models";
import {KeyboardSimulator} from "../../Modules/KeyboardSimulator/KeyboardSimulator";
import {AppSettingsContext} from "../../Core/Contexts/appSettings";
import {EKeyboardSimulatorTypes} from "../../Modules/KeyboardSimulator/enums";


export const ChampionshipPage: React.FC = () => {
    const appSettings = useContext(AppSettingsContext)

    const [textData, setTextData] = useState<IKeyboardText | null>(null);

    useEffect(() => {
        getTextForChampionshipByStage(appSettings?.currentChampionshipRound!)
            .then((res) => {
                if (res.status === 200) {
                    setTextData(prev => ({
                        ...prev,
                        text: res.data.textValue,
                        textLength: Number(res.data.textValue)
                    }));
                }
            })

    }, []);

    return (
        <>
            {!!textData && <KeyboardSimulator keyboardText={textData?.text} type={EKeyboardSimulatorTypes.CHAMPIONSHIP}/>}
        </>
    )
}

ChampionshipPage.displayName = 'ChampionshipPage'
